/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/devbridge-autocomplete@1.4.11/dist/jquery.autocomplete.min.js
 * - /gh/cferdinandi/smooth-scroll@15.0.0/dist/smooth-scroll.polyfills.min.js
 * - /npm/fuse.js@7.0.0/dist/fuse.min.js
 * - /npm/protip@1.4.21/protip.min.js
 * - /npm/javascript-time-ago@2.5.9/bundle/javascript-time-ago.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
